import { SUBTYPE_ENUM, VIDEO_SOURCES } from '../constants';
import { getCurrencySymbol } from './strings';

export const getPickerFormat = (subtype) => {
  let format = 'DD/MM/YYYY HH:mm';

  if (subtype === SUBTYPE_ENUM.DATE) {
    format = 'DD/MM/YYYY';
  } else if (subtype === SUBTYPE_ENUM.TIME) {
    format = 'HH:mm';
  }

  return format;
};

export const scrollToWizardTop = () => {
  const selector = `[id="opportunity-wizard-stepper"]`;
  const wizardElement = document.querySelector(selector);
  wizardElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
}

export function roundToTwo(num) {
  return (Math.round((num + Number.EPSILON) * 100) / 100);
}

export const calculatePercentage = (value, percentage) => roundToTwo(value + value * (percentage / 100));
export const getPercentageValue = (value, percentage) => roundToTwo(value * (percentage / 100));


export const getPaymentSummary = (value, currency, service = 5.9, discount) => {
  const result = {
    currency: getCurrencySymbol(currency),
    fee: value
  };
  result.service = roundToTwo(getPercentageValue(value, service));
  result.discount = discount ? getPercentageValue(value + result.service, -discount) : 0;
  result.total = roundToTwo(value + result.service + result.discount);


  return result;

}

export const getVideoId = (link) => {
  let result = false;
  VIDEO_SOURCES.forEach((source) => {
    const match = link.match(source.regex);
    if (match && match[source.result].length === source.resultLength) {
      result = {
        type: source.type,
        value: match[source.result],
      };
    }
  });
  return result;
};


