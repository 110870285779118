import styled from 'styled-components';
import MuiTypography from '@mui/material/Typography';
import { colors } from '../../../config/colors';

export const StyledTypography = styled(MuiTypography)`

  ${({ maxlines }) =>
    maxlines &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${maxlines};
    
    `}
  && {
    // letter-spacing: 0.1px;
    // font-family: 'DM Sans';
    font-weight: ${({ weight }) => weight};
    display: ${({display}) => display};
    color: ${({color}) => color};
  }
  
  ${({ breakWord }) =>
    breakWord && `word-break: break-word;`
  }

  ${({ onClick }) =>
    onClick && `cursor: pointer; `
  }
  
  ${({ margin }) =>
    margin && `margin: ${margin} !important;`
  }
  ${({ color }) =>
    color && `color: ${color};`
  }
`;

export const StyledLink = styled.a`
  color: ${colors.secondaryColor[900]};
  & :hover {
    color: ${colors.secondaryColor[700]};
  }
`;
