import React from 'react';
import PropTypes from 'prop-types';
import { StyledAvatar } from './style';

const Avatar = ({ src, color, width, ...props }) => <StyledAvatar src={src} color={color} width={width} {...props} />;

Avatar.propTypes = {
  width: PropTypes.string,
  color: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Avatar.defaultProps = {
  width: '60px',
  color: undefined,
  src: undefined,
};

export default Avatar;
