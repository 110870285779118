import cookie from 'cookie';

export const setAuthCookie = (accessToken, withRemember) => {
  document.cookie = cookie.serialize('token', accessToken, {
    sameSite: true,
    path: '/',
    maxAge: withRemember ? 30 * 24 * 60 * 60 : null, // 30 days
  });
};

export const setLocaleCookie = locale => {
  document.cookie = cookie.serialize('locale', locale, {
    sameSite: true,
    path: '/',
    maxAge: 30 * 24 * 60 * 60,
  });
};

export const setCookieAccepting = () => {
  document.cookie = cookie.serialize('accepted',true, {
    sameSite: true,
    path: '/',
    maxAge: 30 * 24 * 60 * 60,
  });
  // {
  //   userAccepted: true,
  // }
};


export const  getCookieAccepted = () => {
  const cookies = cookie.parse(document.cookie);
  // debugger
  return cookies.accepted
}

