import styled from 'styled-components';
import { Avatar as MuiAvatar } from '@mui/material';

export const StyledAvatar = styled(MuiAvatar)`
  && {
    background-color: ${({ theme, color }) => {
      if (color === 'primary') {
        return theme.primary.main
      }
        if (color === 'secondary') {
        return theme.secondary.main
      }
      return color
      }
    };
    width: ${({ width }) => (width || '60px')};
    height: ${({ width }) => (width || '60px')};
  }
`;
