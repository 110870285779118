/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
// import NextLink from 'next/link';
import MuiLink from '@mui/material/Link';
import { Link as NextLink } from '@app/config/i18n';

const { routes } = require('../../../server/routes');

/**
 * This function is used to replace Link params with the params object
 * @param {*} route
 * @param {*} params
 * @returns
 */
const parseRouteUrl = (route, params) => {
  for (const key in params) {
    const regex = new RegExp(`:${key}`, 'g');
    route = route.replace(regex, params[key]);
  }
  return route;
};

const NextComposed = React.forwardRef((props, ref) => {

  const { href, params = {}, query = {}, passHref, prefetch, ...other } = props;
  let component = href;
  let url;
  if (href && Object.prototype.hasOwnProperty.call(routes, href)) {
    let qs = new URLSearchParams({ ...params, ...query }).toString();
    component = routes[href].component;
    component += qs ? `?${qs}` : '';
    url = parseRouteUrl(routes[href].url, params);
    qs = new URLSearchParams(query).toString();
    url += qs ? `?${qs}` : '';

    return (
      <NextLink href={component} passHref={passHref} as={url}>
        <a ref={ref} {...other} />
      </NextLink>
    );
  }

  return <a href={component} ref={ref} {...other} />

});

NextComposed.propTypes = {
  params: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  query: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  prefetch: PropTypes.bool,
};

NextComposed.defaultProps = {
  params: {},
  query: {},
  prefetch: true,
};

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
function Link({ href = '#', activeClassName, className: classNameProps, innerRef, naked, prefetch, ...other }) {

  const router = useRouter();

  const pathname = typeof href === 'string' ? href : href.pathname;
  const component = routes[pathname] ? routes[pathname].component : null;
  const className = router.pathname === component && activeClassName ? activeClassName : '';
  if (naked) {
    return (
      <NextComposed
        className={`${className} ${classNameProps}`}
        ref={innerRef}
        href={href}
        {...other}
      />
    );
  }

  return (
    <MuiLink
      underline="hover"
      component={NextComposed}
      className={`${className} ${classNameProps}`}
      ref={innerRef}
      href={href}
      prefetch={prefetch}
      {...other}
    />
  );
}

Link.propTypes = {
  activeClassName: PropTypes.string,
  params: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  query: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  naked: PropTypes.bool,
  onClick: PropTypes.func,
  prefetch: PropTypes.bool,
};

Link.defaultProps = {
  activeClassName: 'active',
  params: {},
  query: {},
  className: undefined,
  innerRef: undefined,
  naked: false,
  onClick: () => { },
  prefetch: true,
};

export default React.forwardRef((props, ref) => <Link {...props} innerRef={ref} />);
