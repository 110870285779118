import { gql } from '@apollo/client';
// import Fragments from '../fragments';

export const SEND_MESSAGE = gql`
  mutation ($input: MessageInput!) {
    sendMessage(input: $input) {
      sender_id,
      receiver_id,
      message,
      conversation_id,
    }
  }
`;

export const CREATE_CONVERSATION = gql`
  mutation ($input: ConversationInput!) {
    createConversation(input: $input) {
      id
      user_id
      friend_id
      user {
        id
        email
        name
        avatar {
          name
          path
          placeholder
          thumb
        }
      }
      friend {
        id
        email
        name
        avatar {
          name
          path
          placeholder
          thumb
        }
      }
    }
  }
`;
