import { gql } from '@apollo/client';




export const VERIFY_ACCOUNT = gql`
  mutation verifyAccount($input: VerifyAccountInput) {
    verifyAccount(input: $input) {
      status
      message
    }
  }
`;

export const REQUEST_VERFICATION = gql`
  mutation requestVerification {
    requestVerification {
      status
      message
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($input: ChangePassword) {
    updatePassword(input: $input) {
      status
      message
    }
  }
`;

export const CREATE_SCIENTIST_PROFILE = gql`
  mutation createScientistProfile($input: NewScientistProfileInput) {
    createScientistProfile(input: $input) {
      id
      name
      slug
    }
}
`

export const UPDATE_SCIENTIST_PROFILE = gql`
  mutation ($id: ID!,$input: UpdateScientistProfileInput!) {
    updateScientistProfile(id: $id, input: $input) {
      id
      name
      slug
    }
  }
`
export const CONNECT_USER_ACCOUNT = gql`
  mutation connectUserAccount($payInput: CreatePayoutUserAccountInput!) {
    connectUserAccount(input: $payInput) {
      is_done
      next
      url
    }
  }
`;