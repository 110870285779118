import { gql } from '@apollo/client';

export const CREATE_OPPORTUNITY_MUTATION = gql`
  mutation createOpportunity($opportunityInput: NewOpportunityInput) {
    createOpportunity(input: $opportunityInput) {
      id
      title
      slug
      description
      # logo {
      #   name
      #   path
      #   thumb
      # }
    }
  }
`;

export const CLOSE_OPPORTUNITY = gql`
  mutation closeOpportunity($id: ID!) {
    closeOpportunity(id: $id) {
      id
      title
      slug
      is_closed
    }
  }
`;

export const CREATE_FEEDBACK_MUTATION = gql`
  mutation ($id: ID!, $feedbackInput: FeedbackFormBuilderInput!) {
    createOpportunityFeedbackForm(opportunityId: $id, input: $feedbackInput) {
      id
      title
      slug
    }
  }
`;

export const UPDATE_FEEDBACK_MUTATION = gql`
  mutation ($id: ID!, $feedbackInput: FeedbackFormBuilderInput!) {
    updateOpportunityFeedbackForm(id: $id, input: $feedbackInput) {
      id
      title
      slug
    }
  }
`;

export const UPDATE_OPPORTUNITY_MUTATION = gql`
  mutation ($id: ID!, $opportunityInput: UpdateOpportunityInput!) {
    updateOpportunity(id: $id, input: $opportunityInput) {
      id
      title
      slug
      domainkeywords
      tracks {
        id
        track_name
        submission_form {
          id
          terms
          fields {
            id
            label
            is_required
            order
            slug
            subtype
            type
            choices
            child_fields {
              id
              label
              slug
              type
            }
            grades {
              id
              grade
              label
            }
          }
          is_active
          is_skipped
          max_members
          supported_languages
          submission_instructions
        }
      }
    }
  }
`;

export const CREATE_APPLICATION_MUTATION = gql`
  mutation($input: NewApplicationInput) {
    createSubmissionApplication(input: $input) {
      id
      status
      # code
      # opportunity
      # user
      # rate
      # user_rate
      # sections
      # created_at
      # payment_status
      # refunded_at
    }
  }
`;

export const CREATE_APPLICANT_FEEDBACK_MUTATION = gql`
  mutation($input: NewApplicantFeedbackInput) {
    createApplicantFeedback(input: $input) {
      id
    }
  }
`;

export const UPDATE_APPLICANT_FEEDBACK_MUTATION = gql`
  mutation($id: ID!, $input: UpdateApplicantFeedbackInput!) {
    updateApplicantFeedbackData(id: $id, input: $input) {
      id
    }
  }
`;


export const CREATE_REGISTRATION_APPLICATION_MUTATION = gql`
  mutation($input: NewApplicationRegistrationInput) {
    createRegistrationApplication(input: $input) {
      id
      status
      # code
      # opportunity
      # user
      # rate
      # user_rate
      # sections
      # created_at
      # payment_status
      # refunded_at
    }
  }
`;

export const UPDATE_REGISTRATION_APPLICATION_MUTATION = gql`
  mutation($id: ID!, $input: UpdateApplicationRegistrationInput!) {
    updateApplicationRegistrationData(id: $id, input: $input) {
      id
      status
      # code
      # opportunity
      # user
      # rate
      # user_rate
      # sections
      # created_at
      # payment_status
      # refunded_at
    }
  }
`;

export const CREATE_REVIEW_APPLICATION_MUTATION = gql`
  mutation($input: NewReviewApplicationInput) {
    createReviewApplication(input: $input) {
      id
      status
      # code
      # opportunity
      # user
      # rate
      # user_rate
      # sections
      # created_at
      # payment_status
      # refunded_at
    }
  }
`;

export const UPDATE_APPLICATION_MUTATION = gql`
  mutation($id: ID!, $applicationInput: UpdateApplicationInput!) {
    updateApplicationData(id: $id, input: $applicationInput) {
      id
      status
      # code
      # opportunity
      # user
      # rate
      # user_rate
      # sections
      # created_at
      # payment_status
      # refunded_at
    }
  }
`;

export const UPDATE_REVIEW_APPLICATION_MUTATION = gql`
  mutation($id: ID!, $applicationReviewInput: UpdateReviewApplicationInput!) {
    updateReviewApplicationData(id: $id, input: $applicationReviewInput) {
      id
      status
      # code
      # opportunity
      # user
      # rate
      # user_rate
      # sections
      # created_at
      # payment_status
      # refunded_at
    }
  }
`;

export const UPDATE_FORM_MUTATION = gql`
  mutation($id: ID!, $input: ApplicationFormBuilderInput!) {
    updateOpportunityForm(id: $id, input: $input) {
      id
      title
      slug
    }
  }
`;

export const UPDATE_REGISTRATION_FORM_MUTATION = gql`
  mutation updateOpportunityRegistrationForm($id: ID!, $input: RegistrationFormBuilderInput!) {
    updateOpportunityRegistrationForm(id: $id, input: $input) {
      id
      title
      slug
    }
  }
`;

export const UPDATE_SUBMISSION_FORM_MUTATION = gql`
  mutation updateOpportunitySubmissionForm($id: ID!, $input: SubmissionFormBuilderInput!) {
    updateOpportunitySubmissionForm(id: $id, input: $input) {
      id
      title
      slug
    }
  }
`;

export const UPDATE_TRACK_SUBMISSION_FORM_MUTATION = gql`
  mutation updateTrackSubmissionForm($id: ID!, $input: TrackSubmissionFormBuilderInput!) {
    updateTrackSubmissionForm(id: $id, input: $input) {
      track_id
      track_name
      slug
    }
  }
`;

export const UPDATE_REVIEW_FORM_MUTATION = gql`
  mutation updateOpportunityReviewForm($id: ID!, $input: ReviewFormBuilderInput!) {
    updateOpportunityReviewForm(id: $id, input: $input) {
      id
      title
      slug
    }
  }
`;

export const UPDATE_TRACK_REVIEW_FORM_MUTATION = gql`
  mutation updateTrackReviewForm($id: ID!, $input: TrackReviewFormBuilderInput!) {
    updateTrackReviewForm(id: $id, input: $input) {
      track_id
      track_name
      slug
    }
  }
`;

export const UPDATE_PAYMENT_MUTATION = gql`
  mutation updateOpportunityPayment ($id: ID!, $input: UpdatePaymentFormInput!) {
    updateOpportunityPayment(id: $id, input: $input) {
      id
      title
      slug
      target
      experience_level
      deadline_date
      start_date
      end_date
      created_at
      max_age
      min_age
      is_paid
    }
  }
`;

export const DELETE_OPPORTUNITY = gql`
  mutation deleteOpportunity($id: ID!) {
    deleteOpportunity(id: $id) {
      id
      title
      slug
    }
  }
`;

export const LIKE_OPPORTUNITY = gql`
  mutation addOpportunityLike($opportunityId: String!) {
    addOpportunityLike(opportunity_id: $opportunityId) {
      id
      title
      slug
    }
  }
`;

export const DELETE_TRACK = gql`
  mutation deleteTrack($id: ID!) {
    deleteTrack(id: $id) {
      id
      track_name
    }
  }
`;
export const UPDATE_TRACK = gql`
mutation updateTrack($id: ID!, $trackInput: UpdateTrackInput!) {
  updateTrack(id: $id, input: $trackInput) {
    track_id
    track_name
  }
}
`;

export const UPLOAD_FILES = gql`
  mutation uploadFiles($files: [Upload!]!) {
    uploadFiles(files: $files) {
      name
    path
    full_path
    placeholder
    }
  }
`;

export const SEND_PAYMENT_REMINDER = gql`
  mutation sendRegistrationPaymentReminder($input: [String!]!) {
    sendRegistrationPaymentReminder(applications: $input) {
      id
      email
      name
      elements {
        field_id
        field_name
        value
      }
    }
  }
`;

export const CREATE_CERTIFICATE_OF_PARTICIPANT = gql`
  mutation createCertificateOfParticipation($id: [ID!]!) {
    createCertificateOfParticipation(id: $id) {
      id
      url
    }
  }
`;
