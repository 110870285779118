import { gql } from '@apollo/client';

export const SAVE_TALK_SESSION = gql`
  mutation saveTalkSession($input: TalkSessionInput) {
    saveTalkSession(input: $input) {
      title
    }
  }
`;

export const DELETE_TALK_SESSION = gql`
  mutation deleteTalkSession($slug: String!) {
    deleteTalkSession(slug: $slug) {
      id
    }
  }
`;
