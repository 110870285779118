import { CURRENCIES } from '../constants';

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const getCurrencySymbol = (code) =>
  // let symbol = '';
  // if (currency.toUpperCase() === 'USD') {
  //   symbol = '$';
  // } else if (currency.toUpperCase() === 'EUR') {
  //   symbol = '€';
  // }
  // return symbol;
  CURRENCIES[code.toUpperCase()] ? CURRENCIES[code.toUpperCase()].symbol : code;

export const boolToText = (bool) => (bool ? 'yes' : 'no');
export const urlCheckRegex = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);

export const isAbsoluteURL = (url) => url.indexOf('://') > 0 || url.indexOf('//') === 0;

export const markupJson = (jsonData) => ({__html: JSON.stringify(jsonData)})
