import { 
    USER_QUERY,
    RESOURCE_AUTHORIZATION_QUERY
} from "@app/apollo/queries";


const userRoles = {
    user: 'user',
    organiser: 'organiser',
    admin: 'admin'
}

function isAuthenticated (user) {
    return typeof user === 'object' && user !== null
}

function hasRole(user, roleName) {
    return isAuthenticated(user) && user.role.name === roleName
}

export async function getAuthenticatedUser(apolloClient) {
    try {
        const {
            data: { me: user = null} = {}
        } = await apolloClient.query({
            query: USER_QUERY
        });
        return user;
    } catch(err) {
        console.error(err);
        return null;
    }
}

async function canAccess(apolloClient, ability, resource, ResourceId) {
    try {
        const { data } = await apolloClient.query({
            query: RESOURCE_AUTHORIZATION_QUERY,
            variables: {
                input: {
                    resource,
                    id: ResourceId,
                    ability
                }
            }
        })
        return data.can;
    } catch(err) {
        return false;
    }
}
export const isAdmin = (user) => hasRole(user, userRoles.admin);
export const isOrganiser = (user) => hasRole(user, userRoles.organiser) || isAdmin(user) ;
export const isUser = (user) => isAuthenticated(user);


export const canManageOrganisation = async (apolloClient,user, organisationId) => {
    if (isAdmin(user)) {
        return true;
    }
    if(isOrganiser(user)) {
        return canAccess(apolloClient, 'EDIT', 'organisation', organisationId )
    }
    return false;
};


export const canManageOpportunity = async (apolloClient, user, opportunityId) => {
    if (isAdmin(user)) {
        return true;
    }
    if(isOrganiser(user)) {
        return canAccess(apolloClient, 'EDIT', 'opportunity', opportunityId )
    }
    return false;
    
}

export const canReviewOpportunity = async (apolloClient, user, opportunityId) => {
    if (isAdmin(user)) {
        return true;
    }
    return canAccess(apolloClient, 'REVIEW', 'opportunity', opportunityId )
}

export const canManageApplication = async (apolloClient, user, applicationId) => {
    if (isAdmin(user)) {
        return true;
    }
    return canAccess(apolloClient, 'EDIT', 'application', applicationId )
};
