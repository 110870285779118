/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledSnackbar, TypedContainer,MessageContainer } from './style';

const Snackbar = ({
  open,
  close,
  action,
  anchorOrigin,
  message,
  onClose,
  type,
  ...props
}) => {
  let icon = null;
  switch (type) {
    case 'success':
      icon = <CheckCircleIcon />;
      break;

    case 'info':
      icon = <InfoIcon />;
      break;

    case 'warning':
      icon = <WarningIcon />;
      break;

    case 'error':
      icon = <CancelRoundedIcon />;
      break;

    case 'loading':
      icon = <CircularProgress size={25} />;
      break;
    default:
      icon = <InfoIcon />;
      break;
  }

  let snackbarMessage = message;

  if (icon) {
    snackbarMessage = (
      <TypedContainer type={type}>
        {icon} <MessageContainer>{message} </MessageContainer>
      </TypedContainer>
    );
  }

  return (
    <StyledSnackbar
      action={[
        ...action,
        close ? (
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null,
      ]}
      open={open}
      onClose={onClose}
      message={snackbarMessage}
      anchorOrigin={anchorOrigin}
      type={type}
      autoHideDuration={1000}
      {...props}
    />
  );
};

Snackbar.propTypes = {
  autoHideDuration: PropTypes.number,
  close: PropTypes.bool,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  action: PropTypes.node,
  onClose: PropTypes.func
};

Snackbar.defaultProps = {
  autoHideDuration: 3000,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  close: false,
  onClose: () => false,
  action: [],
};

export default Snackbar;
