import { USER_QUERY } from "../apollo/queries";
import { initalizeCache } from '../apollo/apolloStore';

export const requestQuery = async (apolloClient, gqlQuery) => {
    // TODO: handle failed authentication requests 
    try {
        const {  data } = await apolloClient.query({
            query: gqlQuery
        })
        return data;
    } catch(err) {
        return null;
    }
}

function getAuthenticatedUser() 
{
    let authenticatedUser = null;
    return async (apolloClient) => {
        // Make sure to reauthenticate user for every server-side request so that data
        // isn't shared between connections (which would be bad)
        if (typeof window !== 'undefined' && authenticatedUser ) {
            return authenticatedUser;
        }
        try {
            initalizeCache(apolloClient.cache);
            const user = await requestQuery(apolloClient, USER_QUERY);
            if (user && user.me) {
                authenticatedUser = user.me;
                return authenticatedUser;
            }
            return null
        } catch (err) {
            return null;
        }
    }
}

export const checkAuthentication = getAuthenticatedUser();