import styled from 'styled-components';
import MuiSnackbar from '@mui/material/Snackbar';

export const StyledSnackbar = styled(MuiSnackbar)`

/* && {
  max-width: 30rem;
  min-width: 25rem; 
 
} */
  .MuiSnackbarContent-root {
    /* padding: 0.7rem 1rem;
    margin-top: 4.0625rem;*/
   
    background-color: ${({ theme, type }) => {
    let color = null;
    switch (type) {
      case 'success':
        color = theme.green;
        break;

      case 'info':
        color = theme.primaryColor;
        break;

      case 'loading':
        color = theme.primaryColor;
        break;

      case 'warning':
        color = theme.yellow;
        break;

      case 'error':
        // eslint-disable-next-line prefer-destructuring
        color = theme.red[500];
        break;

      default:
        color = theme.primaryColor;
        break;
    }

    return color;
  }};
  }
  .MuiSnackbarContent-message {
    /* flex-basis: 85%;   */
    /* max-width */
    max-width: 30rem;

  }
`;

export const TypedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .message-container {
    margin-left: 1rem;
  }
  svg {
    color: white;
  } 
 
`;

export const MessageContainer = styled.div`
margin-left: 1rem;
`;
