/* eslint-disable react/forbid-prop-types */
import Snackbar from '@molecules/Snackbar';
import { useTranslation } from 'config/i18n';
import { ERRORS_CATEGORIES } from 'constants/errors';
import PropTypes from 'prop-types';
import React from 'react';

const MutationsStatus = ({ loading, error, success }) => {
  const { t } = useTranslation(['common']);
  // console.table(error)
  const [open, setOpen] = React.useState(false);

  let type = 'loading';

  if (error) {
    type = 'error';
  }

  if (success) {
    type = 'success';
  }
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (loading || error || success) {
      setOpen(true);
    }
  }, [loading, error, success]);

  const getMessage = (snackType) => {
    let message = [];
    if (snackType === 'loading') {
      message = [<div>{t('loading')}</div>];
    }
    if (snackType === 'success') {
      message = [<div>{t('success')}</div>];
    }
    if (type === 'error') {
      const {
        graphQLErrors = null,
        message: graphMessage = null,
        // status = null
      } = error || {};
      // if (status) {
      message = [<>{t('sorry.error')}</>];

      if (graphQLErrors && graphQLErrors.length > 0) {
        // iterate all GraphQL errors
        for (let i = 0; i < graphQLErrors.length; i += 1) {
          // Check error type:
          switch (graphQLErrors[i].extensions.category) {
            // If it's a validation error display validation message
            case ERRORS_CATEGORIES.VALIDATION: {
              message = [
                // <>
                //   <div>{graphMessage}</div>
                //   <br />
                // </>,
              ];
              const validationMessages = Object.values(
                graphQLErrors[i].extensions.validation
              ).reduce((acc, val) => [...acc, ...val]);

              message.push(
                validationMessages.map((msg) => (
                  <>
                    {msg}
                    <br />
                  </>
                ))
              );
              break;
            }
            //  if it's an authentication error display the message from the server.
            case ERRORS_CATEGORIES.AUTHENTICATION:
              message = [<div>{graphMessage}</div>];

              break;

            case ERRORS_CATEGORIES.BAD_REQUEST:
              message = [<div>{graphMessage}</div>];

              break;

            // otherwise display the default message:
            default:
              message = [<>{t('sorry.error')}</>];
              break;
          }
        }
      }
    }
    return <div>{message}</div>;
  };

  return (
    <Snackbar
      type={type}
      open={open}
      message={getMessage(type)}
      close={type !== 'loading'}
      autoHideDuration={type !== 'loading' ? 50000 : null}
      onClose={handleClose}
    />
  );
};

MutationsStatus.propTypes = {
  loading: PropTypes.bool,
  success: PropTypes.object,
  error: PropTypes.object,
};

MutationsStatus.defaultProps = {
  loading: undefined,
  error: undefined,
  success: undefined,
};

export default MutationsStatus;
